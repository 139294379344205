/* eslint-disable */
'use strict';

var _saddlebagGa = require('./saddlebag-ga');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var trackOutboundLink = function trackOutboundLink(label) {
  var category = (window.skyScannerTheme || {}).pageType || 'news';
  var action = 'text-link-click';

  var shouldLogSingleGA = true;
  if (window.trackoldingData
        && window.trackoldingData.shouldLogSingleGA !== undefined
        && !window.trackoldingData.shouldLogSingleGA
      ) {
      shouldLogSingleGA = false;
  }

  var gaData = {
    category: category,
    action: action,
    label: label,
    value: ''
  };

  var uaPageView = encodeURI(window.location.pathname);
  try {
    _saddlebagGa.gaTracking.handleAnalyticsEvents(gaData, uaPageView, shouldLogSingleGA);
  } catch (err) {
    return err;
  }
  return true;
};

var articleContentElement = document.querySelector('.content');
var outboundLinks = articleContentElement.getElementsByTagName('a');
for (var i = 0, len = outboundLinks.length; i < len; i += 1) {
  (function addListener(link) {
    if (!link.href) {
      return;
    }
    link.addEventListener('click', function (e) {
      e.preventDefault();
      var target = link.href;
      var label = link.textContent;
      var newWindow = link.target === '_blank';
      setTimeout(function () {
        if (newWindow) {
          window.open(target, '_blank');
        } else {
          window.location.href = target;
        }
      }, 200);
      trackOutboundLink(label);
    }, false);
  })(outboundLinks[i]);
}
